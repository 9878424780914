import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 */
export class OfferReaccept extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
  }

  get data() {
    return {
      creditApplicationId: `${this._creditApplicationId}`,
    }
  }


  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/offer/reaccept',
      params: self.data
    }))
  }
}
