import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ArrowForward from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function SelectedListItem(props) {

    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    let arraylegales = props.Legales.map((row) => 
        ({ icon: <ArrowForward />, title: <a href={row.link} target="_blank" rel="nofollow noopener noreferrer" >{row.title}</a> })
    )

    const handleListItemClick = (index) => {
        setSelectedIndex(index);
    };

    return (
        <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders" key="mainmailboxfolders">
                {arraylegales.map((row, index) => {
                    return <><ListItem
                        key={index + 1}
                        button
                        selected={selectedIndex === 0}
                        onClick={() => handleListItemClick(0)}
                    >
                        <ListItemIcon key={index + 2}>
                            {row.icon}
                        </ListItemIcon>
                        <ListItemText primary={row.title} />
                    </ListItem><Divider /></>
                })
                }
            </List>
        </div>
    );
}


