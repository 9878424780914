import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export default function Checkboxes({onChange, check, id}) {

    const handleChange = (event) => {
        onChange(event.target.checked)
    };

    return (
        <div>
            <Checkbox
                checked={check}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                id={id}
            />
        </div>
    );
}
